body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*http://colorpalettes.net/color-palette-3958/*/

.TuOcLbrbK0h9wLL4W9fq9 {
    margin-left: 50px;
}

._20ORX8EM9zh3Gw6DTCcf8g {
    display: inline-block;
    width: 200px;
    padding: 15px 30px 10px;
    margin-right: 10px;
    float: right;
}

._3mg3fett3pSrNArVWNVB1a {
    display: inline-block;
    width: 50%;
    margin: auto;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
    color: #80807E;
}

._4HmeP6T4j0TRBf_Dx6KUo {
    width: 1400px;
    margin: auto;

}

._1Sha-gcZkcifeXq6eEFJ3Q {
    text-align: center;


    margin: auto;

}


._2Mx80RkVRm749SJ7fWr-ts {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

._26ETniVykNmp0lChrF3Z7E {
    margin-top: 10px;
    display: inline-block;
    width: 50%;
    float: left;
}

.cCo8bbs4HnsJMk47lGDcl {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 1920px;
    background-image: url(/static/media/future-medicine.5706134d.jpg);
}

._1SssXorLUncJNysVFiUn5j {
    background-color: #FAFAF6;
}

.CEdNM5R5diGQ4qgDGxCpV {
    display: inline-flex;
}

._3ve9LK_bc0WYan_WqIrvRU {
    font-weight: 400;
    font-size: 18px;
    color: #8e2807;
    letter-spacing: 1px;
}

.SwawLdfq8cWMjOORgecDA {
    font-weight: 400;
    font-size: 16px;
    color: #8e2807;
    letter-spacing: 1px;
}

.Yoe0m-bCcuOVBd4FUpTYA {
    font-weight: 600;
    color: #537C8E;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 1em;
    width: 30%;
}

.zOPpxp0ToyQcqV-GmjMFB {
    display: table-cell;
    background-color: cornflowerblue;
    text-align: center;
    height: 2.5em;
}

._2S1u8x2gMS8Mcjc-G4NsRo {
    font-weight: 600;
    color: #FFFFFF;
    letter-spacing: 1px;
    vertical-align: middle;
    margin-top: 0.5em;
}

._2G2MoTNHPfsvQJCQQwG2yf {
    font-weight: 600;
    color: #537C8E;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 1em;
    width: 90%;
}

._1puFmrdGR-faZPWk_FUOcX {
    font-weight: 600;
    font-size: 16px;
    color: #537C8E;
    letter-spacing: 1px;
    position: relative;

    text-align: left;
    margin-left: 50px;
    margin-bottom: 20px;
}

._2d7NHhPHqQYLMjmrcuiFBY{
    padding: 15px 30px;
    margin: 15px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #537C8E;
    letter-spacing: 1px;
    width: 600px;
}

._2T2YtCcZGJiOxi_4A3L5Ei {
    padding: 15px 30px 0px;
    display: inline-block;
    font-weight: 600;
    color: #537C8E;
    margin: 15px 0;
    letter-spacing: 1px;
    text-indent: 20px;
    text-transform: uppercase;
    line-height: 30px;
    border-bottom: 3px solid;
    -webkit-border-image: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 1) 1%,
            rgba(46, 189, 189, 1) 50%,
            rgba(83, 124, 142, 1) 100%
    ) 0 0 100% 0/0 0 3px 0 stretch;
    border-image: linear-gradient(
            to left,
            rgba(255, 255, 255, 1) 1%,
            rgba(46, 189, 189, 1) 50%,
            rgba(83, 124, 142, 1) 100%
    ) 0 0 100% 0/0 0 3px 0 stretch;
}

._38R3bAr69Oqc6io-3zZ2B5 {
    background: no-repeat scroll left / 20% auto rgba(0, 0, 0, 0);
    display: inline-block;
    color: #fef8ed;
    position: relative;
    vertical-align: middle;
    text-indent: 24px;
}

.NuIr5kwwPecV1qq6Z3AI- {
    background:  no-repeat scroll left / 30% auto rgba(0, 0, 0, 0);
    display: inline-block;
    color: #fef8ed;
    position: relative;
    vertical-align: middle;
    text-indent: 24px;
}
._2j0MKErdHQgmesTjewd9a7 {
    position: relative;
    width: 800px;
    height: 300px;
}
._2zKlzBwQ1vjMmaIHlCEF2L {
    position: relative;
    width: 800px;
    height: 300px;
    margin-top: 0px;
}
._2AAZ3kHPa3DvuxdB-vaUVK {
    text-align: center;
    font-family: sans-serif;
}
._36Mbrf6RoHrvnfD9PbA0iE {
    font-family: sans-serif
}

._1S_9B2W3kKfQO10oZgJthn {
    float: right;
    margin-right: 5%;
}
._2ReQuZ3bVKNcfYZ-E8iI3e {
    float: left;
    margin-left: 5%;
}
._1aYxG3KfuT7c9RuyQoTapc {
    display:flex;
    flex-direction:row;
    margin-left: 5%;
    margin-top: 2%;
}

._3hDenqECOTMMt87mbM-eMv {
    position: relative;
    width: 800px;
    height: 95%;
    margin-top: 0px;
}

._2KNp1HvyWSno5gl4dkg5Dm {
    horiz-align: center;
}
._2FrrnaKFjhR6VrQyWpjX8F {
    margin-left: 0;
    margin-bottom: 30px;
    margin-top: 1%;
}

._3PllJJxYmE1rEMa5CqZX_z {
    display: flex;
}

._27fcd6YVy9FDMpF-ooLHAb {
    position: relative;
    margin: auto;
    float: left;
    width: 45%;
    height: auto;
}

._1vlZPH1ILb6BhAnWKqe-Hu {
    position: relative;
    float: right;
    margin: auto;
    width: 45%;
    height: auto;
}

._3XN-CzdpKrZwd-D7ui8Pm_ {
    display:flex;
    flex-direction:column;
    position: relative;
    margin-left: 5%;
    margin-top: 2%;
}

._2wpBrEu2nFAy0PFjBBt19L {
    width: 95%;
    height: 95%;
    margin-top: 2%;
    margin-left: 2%;
}

._7gxgXWV3ux8DziJ7CXjxy {
    width: 15%;
    height: 70px;
    margin-bottom: 2%;
}

._2W_v6a2bxeXlcuVT95HQol {
    width:800px;
    height: 350px;
    position: relative;

}

._2DSl8GxXxaO9v6keRvyaTg {
    height: 800px;
}

._1xXk1pmmZaaa0rBYuGwnw6 {
    margin: 2%;
}

._3hnFof5UxfGFfG09VJ8Yjc {
    margin-top: 4%;
}
.NOPreDPK9GDxTum43-ZYH {
    margin-right: 40px;
    width: 800px;
}
.FpNzNTHrK7xQYp_Bow6R4 {
    background: #DCDCDC;
    border-color: #A9A9A9
}


.field {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    position: relative;
    background-color: #91E0F2;
    -webkit-transition: 0.3s all;
    transition: 0.3s all;
}

::-webkit-input-placeholder {
    color: black;
    opacity: 1;
}

::-ms-input-placeholder {
    color: black;
    opacity: 1;
}

::placeholder {
    color: black;
    opacity: 1;
}

.field:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.field.focused input {
    padding: 24px 16px 8px 16px;
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.field.focused input + label {
    top: 4px;
    opacity: 1;
    color: #012E40;
}

.field.locked {
    pointer-events: none;
}

.field input {
    width: 100%;
    height: 56px;
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    -webkit-transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none;
}

.field input + label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: 0.1s all ease-in-out;
    transition: 0.1s all ease-in-out;
}

.field input + label.error {
    color: #ec392f;
}

.field input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.field input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.field input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.field input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
}
